<template>
	<BaseLoading v-if="editInfo.isLoading" />
	<FormCategoryAdditionalFee
		v-else
		:fee="editInfo.data.fee"
		:category-list="editInfo.data.items"
		:is-submitting="editInfo.isUpdating"
		@onSubmit="handleSubmit"
		@onRemoveRule="handleRemoveRule"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormCategoryAdditionalFee from '@/components/FormCategoryAdditionalFee.vue';
import { priceToAPI } from '../assets/js/helpers';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../enums/shippings';

export default {
	name: 'ShippingCategoryAdditionalFeeEdit',

	components: {
		FormCategoryAdditionalFee,
	},
	data() {
		return {
			feeId: this.$route.params.categoryAdditionalFeeId,
		};
	},
	computed: {
		...mapState('additionalFees', {
			editInfo: (state) => state.additionalFees[SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY].edit,
		}),
	},
	async mounted() {
		await this.getAdditionalFee({
			id: this.feeId,
			type: SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY,
		});
	},
	methods: {
		...mapActions({
			getAdditionalFee: 'additionalFees/getAdditionalFee',
			updateAdditionalFee: 'additionalFees/updateAdditionalFee',
			deleteAdditionalFee: 'additionalFees/deleteAdditionalFee',
		}),

		async handleSubmit(fee, categoryIds) {
			const params = {
				fee: priceToAPI(fee),
				type: SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY,
				item_ids: categoryIds,
			};

			await this.updateAdditionalFee({
				type: SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY,
				id: this.feeId,
				params,
			});
		},

		async handleRemoveRule() {
			await this.deleteAdditionalFee(this.feeId);
			this.$router.push({ name: 'ShippingSetting' });
		},
	},
};
</script>
